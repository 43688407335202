import React, { FC, useEffect, useRef } from 'react';
import { QrReaderProps } from './QrReader.specs';
import QrScanner from 'qr-scanner';

export const QrReader: FC<QrReaderProps> = ({
  preferredCamera = "environment",
  scansPerSecond = 2,
  videoId = "video",
  ViewFinder,
  onResult,
}) => {
  const videoRef = useRef(null);

  const isMediaDevicesSupported = () => {
    const isMediaDevicesSupported = typeof navigator !== "undefined" && !!navigator.mediaDevices;
    if (!isMediaDevicesSupported) {
      console.warn("[ReactQrReader]: MediaDevices API has no support for your browser.");
    }
    return isMediaDevicesSupported;
  };

  useEffect(() => {
    if (!isMediaDevicesSupported()) {
      return onResult(null, new Error("MediaDevices API has no support for your browser."));
    }

    const codeReader = new QrScanner(
      videoRef.current,
      result => {
        onResult(result, null);
        codeReader.stop();
        codeReader.destroy();
      }, {
      onDecodeError: e => onResult(null, typeof e === "string" ? new Error(e) : e),
      preferredCamera: preferredCamera,
      maxScansPerSecond: scansPerSecond,
    });

    codeReader.start()
  }, []);

  return (
    <section>
      <div className="qr-reader__container">
        {!!ViewFinder && <ViewFinder />}
        <video
          muted
          id={videoId}
          ref={videoRef}
          className="reader__video"
        />
      </div>
    </section>
  );
};
