import { Store, Role, User, Bar, Session, Bill, Customer, ConnectionTokens, Product, Statistics } from '../DemoTypes';

type Response<T> = Promise<{ data: T }>

async function asResponseDefined(x: any): Response<any> {
    if (!x) {
        return Promise.reject("Dummy data not found");
    }
    return { data: x };
}

async function asDefined(x: any): Promise<any> {
    if (!x) {
        return Promise.reject("Dummy data not found");
    }
    return x;
}

/////////////////////////////////////////////

const duplicate = (x) => JSON.parse(JSON.stringify(x))

const randomID = (): string => Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();

const activeUserId = (): string => localStorage.getItem("@ActiveUser");
const setActiveUserId = (id: string): void => localStorage.setItem("@ActiveUser", id);

const saveDB = (data): void => localStorage.setItem("@BartapDemoDB", JSON.stringify(data));

export const resetDB = (): void => localStorage.setItem("@BartapDemoDB", JSON.stringify(dummyDB()));

function loadDB(): Store {
    let data = JSON.parse(localStorage.getItem("@BartapDemoDB"));
    if (!data) {
        data = dummyDB();
        saveDB(data);
    }
    return data
}

/////////////////////////////////////////////

export function checkTokenValidity(token) {
    const id = activeUserId();
    return id !== undefined && id !== null;
}

const totalSpent = (bills: Bill[]): number => bills.reduce((acc, b) => acc + b.totalPrice, 0);

const totalNotYetPayed = (bills: Bill[]): number => bills.reduce((acc, b) => b.isPayed ? acc : acc + b.totalPrice, 0);

const mostExpensiveBill = (bills: Bill[]): Bill => bills.reduce((acc, b) => b.totalPrice > acc.totalPrice ? b : acc);

const mostSoldProduct = (bills: Bill[]): Product => {
    const productAmountMap: Map<string, number> = new Map();

    const orders = bills.flatMap(b => b.orders);
    orders.forEach(o => productAmountMap.set(o.product.id, (productAmountMap.get(o.product.id) || 0) + o.amount));

    let highestAmount: number = -1;
    let mostSoldProductId: string;

    productAmountMap.forEach((amount, productId) => {
        if (amount > highestAmount) {
            highestAmount = amount;
            mostSoldProductId = productId;
        }
    });

    return orders.flatMap(o => o.product).find(p => p.id === mostSoldProductId);
}

function generateStats(bills: Bill[]): Statistics {
    return {
        mostSoldProduct: mostSoldProduct(bills),
        mostExpensiveBill: mostExpensiveBill(bills),
        totalSpent: totalSpent(bills),
        totalNotYetPayed: totalNotYetPayed(bills),
    };
}

/////////////////////////////////////////////

export async function getOwnedBars(): Promise<Bar[]> {
    const db = loadDB();
    const user = db.users.find(user => user.id === activeUserId());
    const barIds = user.authorizations
        .filter(auth => auth.role === Role.OWNER)
        .map(auth => auth.barId);
    return asDefined(db.bars.filter(bar => barIds.includes(bar.id)));
}

export async function getBarById(barId): Promise<Bar> {
    return asDefined(loadDB().bars.find(bar => bar.id === barId));
}

export async function getCustomersOfBar(barId): Promise<Customer[]> {
    return asDefined(getBarById(barId).then(r => r.customers));
}

export async function getSessionsOfBar(barId): Promise<Session[]> {
    return asDefined(getBarById(barId).then(b => b.sessions));
}

export async function getCustomerOfBar(barId, customerId): Promise<Customer> {
    return asDefined(getCustomersOfBar(barId).then(r => r.find(c => c.id === customerId)));
}

export async function getAccountById(accountId): Promise<User> {
    return asDefined(loadDB().users.find(u => u.id === accountId));
}

export async function getMyAccount(): Promise<User> {
    return asDefined(loadDB().users.find(u => u.id === activeUserId()));
}

export async function getBillById(barId, sessionId, billId): Promise<Bill> {
    return asDefined(
        getBarById(barId)
            .then(r => r.sessions.find(s => s.id === sessionId).bills.find(b => b.id === billId))
    );
}

export async function getBillsOfCustomer(barId, customerId): Promise<Bill[]> {
    return asDefined(
        getBarById(barId)
            .then(r => r.sessions.flatMap(s => s.bills).filter(b => b.customer.id === customerId))
    );
}

export async function getMyBillsByBarId(barId): Promise<Bill[]> {
    return getBillsOfCustomer(barId, activeUserId());
}

export async function getMyActiveBillByBarId(barId): Promise<Bill> {
    const bar = await getBarById(barId);
    const session = bar.sessions.find(s => s.closedDate === undefined);
    if (session === undefined) {
        return Promise.reject("No active sessions")
    }
    const b = session.bills.find(b => b.customer.id === activeUserId())
    if (b === undefined) {
        return Promise.reject("No active bill")
    }
    return asDefined(b);
}

export async function getConnectedBars(): Promise<Bar[]> {
    const db = loadDB();
    const user = db.users.find(user => user.id === activeUserId());
    const barIds = user.authorizations.map(auth => auth.barId);
    return db.bars.filter(bar => barIds.includes(bar.id));
}


export async function signUp(email, userName, password, firstName, lastName): Response<User> {
    const db = loadDB();
    const existingUser = db.users.find(u => u.email === email || u.username === userName);
    if (existingUser) {
        return Promise.reject({ message: "Account with email already exists." });
    }
    const user: User = {
        id: randomID(),
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: password,
        username: userName,
        phoneNumber: undefined,
        authorizations: [],
    };
    db.users.push(user)
    saveDB(db);
    return asResponseDefined(user);
}


export async function login(username, password): Promise<Boolean> {
    const user = loadDB().users.find(u => u.username === username);
    if (!user) {
        return Promise.reject({ message: "User does not exist" });
    }
    if (user.password !== password) {
        return Promise.reject({ message: "Password is incorrect" });
    }
    setActiveUserId(user.id);
    return true
}

export async function logout(): Promise<void> {
    setActiveUserId(undefined);
}

export async function updateAccountInfo(customerId, firstName, lastName, phone): Promise<void> {
    const db = loadDB();
    const user = db.users.find(u => u.id === customerId);
    user.firstName = firstName;
    user.lastName = lastName;
    user.phoneNumber = phone;
    saveDB(db);
}


export async function getConnectAccountToken(barId, accountId): Promise<string> {
    const db = loadDB();
    const token = crypto.randomUUID();;
    db.connectionTokens.push({ barId: barId, token: token })
    saveDB(db);
    return asDefined(token);
}

export async function connectAccountWithToken(token): Promise<void> {
    const db = loadDB();

    const ct: ConnectionTokens = db.connectionTokens.find(ct => ct.token === token);
    if (!ct) {
        return Promise.reject({ message: "Connection token invalid or already used." });
    }
    const user = db.users.find(u => u.id === activeUserId());
    if (!user) {
        return Promise.reject({ message: "The user yu are logged in with does not exist or is disabled." });
    }
    user.authorizations.push({ barId: ct.barId, role: Role.CUSTOMER });
    saveDB(db);
}

export async function getStatisticsOfBar(barId): Promise<Statistics> {
    const sessions = await getSessionsOfBar(barId);
    const bills = sessions.flatMap(s => s.bills);
    return generateStats(bills);
}

export async function getStatisticsOfCustomer(customerId) {
    const sessions = loadDB().bars.flatMap(b => b.sessions);
    const bills = sessions.flatMap(s => s.bills).filter(b => !!b.customer.userId && b.customer.userId === customerId);
    return generateStats(bills);
}


export async function getGlobalCustomerStatistics() {
    const sessions = loadDB().bars.flatMap(b => b.sessions);
    const bills = sessions.flatMap(s => s.bills).filter(b => !!b.customer.userId && b.customer.userId === activeUserId());
    return generateStats(bills);
}

export async function getCustomerBarStatistics(barId) {
    const sessions = await getSessionsOfBar(barId);
    const bills = sessions.flatMap(s => s.bills).filter(b => !!b.customer.userId && b.customer.userId === activeUserId());
    return generateStats(bills);
}

export async function getCustomerByIdStatistics(barId, customerId) {
    const sessions = await getSessionsOfBar(barId);
    const bills = sessions.flatMap(s => s.bills).filter(b => !!b.customer.userId && b.customer.userId === customerId);
    return generateStats(bills);
}


function dummyDB(): Store {
    const now = new Date();
    now.setHours(now.getHours() - 1);

    const admin: User = {
        id: "1",
        firstName: "Chalmun",
        lastName: "Wookie",
        username: "admin",
        email: "chalmun@moseislycantina.to",
        phoneNumber: "+31612345678",
        password: "admin",
        authorizations: [{ barId: "51", role: Role.OWNER }]
    };

    const obiwan: User = {
        id: "99",
        firstName: "Obi-Wan",
        lastName: "Kenobi",
        username: "obikenobi",
        email: "obiwan@jediorder.com",
        phoneNumber: "+31612345678",
        password: "hellothere",
        authorizations: []
    };

    const customer0: Customer = {
        id: "0",
        userId: "1",
        name: "Chalmun Wookie"
    }
    const customer1: Customer = {
        id: "2",
        userId: undefined,
        name: "Boba Fett"
    }
    const customer2: Customer = {
        id: "3",
        userId: undefined,
        name: "Ahsoka Tano"
    }
    const customer3: Customer = {
        id: "4",
        userId: undefined,
        name: "Jarjar Binks"
    }
    const customer4: Customer = {
        id: "5",
        userId: undefined,
        name: "Anakin Skywalker"
    }
    const customer5: Customer = {
        id: "6",
        userId: "99",
        name: "Obi-Wan Kenobi"
    }
    const customer6: Customer = {
        id: "7",
        userId: undefined,
        name: "Han Solo"
    }
    const customer7: Customer = {
        id: "8",
        userId: undefined,
        name: "Luke Skywalker"
    }

    const session: Session = {
        id: "11",
        name: "Luke's birthday",
        creationDate: now,
        date: now,
        closedDate: undefined,
        isLocked: false,
        bills: [],
    }

    const product1: Product = {
        id: "21",
        name: "Rancor’s Toothpick",
        brand: "Outer Rim Supply Co.",
        price: 5.0,

    };
    const product2: Product = {
        id: "22",
        name: "The Frost Awakens",
        brand: "Outer Rim Supply Co.",
        price: 5.0,

    };
    const product3: Product = {
        id: "23",
        name: "Kylo Rye",
        brand: "Sienar Technologies",
        price: 5.0,

    };
    const product4: Product = {
        id: "24",
        name: "Bothan",
        brand: "Mehrak Corporation",
        price: 6.5,

    };
    const product5: Product = {
        id: "25",
        name: "Hoth Toddy",
        brand: "Mehrak Corporation",
        price: 6.5,

    };
    const product6: Product = {
        id: "26",
        name: "Tatooine Pilsner",
        brand: "Mehrak Corporation",
        price: 3.25,

    };
    const product7: Product = {
        id: "27",
        name: "Temple Island Blue Milk",
        brand: "Luke's Brewery",
        price: 1.75,

    };
    const product8: Product = {
        id: "28",
        name: "Dark Side",
        brand: "Luke's Brewery",
        price: 11.50,

    };
    const product9: Product = {
        id: "29",
        name: "Meiloorun Fruit",
        brand: "Ezra's Chest",
        price: 2.50,
    };

    const orders1 = [{
        id: "30",
        amount: 2,
        creationDate: new Date("2024-05-09T16:44:01Z"),
        product: product1,
        bartender: customer0,
    },
    {
        id: "31",
        amount: 1,
        creationDate: new Date("2024-05-09T16:32:01Z"),
        product: product4,
        bartender: customer0,
    },]

    const orders2 = [{
        id: "32",
        amount: 2,
        creationDate: new Date("2024-05-09T16:44:01Z"),
        product: product1,
        bartender: customer0,
    },
    {
        id: "33",
        amount: 4,
        creationDate: new Date("2024-05-09T15:31:01Z"),
        product: product6,
        bartender: customer0,
    },
    {
        id: "34",
        amount: 1,
        creationDate: new Date("2024-05-09T17:25:01Z"),
        product: product2,
        bartender: customer0,
    },
    {
        id: "35",
        amount: 1,
        creationDate: new Date("2024-05-09T16:32:01Z"),
        product: product8,
        bartender: customer0,
    },]

    const orders3 = [{
        id: "36",
        amount: 1,
        creationDate: new Date("2024-05-09T16:44:01Z"),
        product: product2,
        bartender: customer0,
    },
    {
        id: "37",
        amount: 2,
        creationDate: new Date("2024-05-09T15:31:01Z"),
        product: product9,
        bartender: customer0,
    },
    {
        id: "38",
        amount: 4,
        creationDate: new Date("2024-05-09T17:25:01Z"),
        product: product1,
        bartender: customer0,
    },
    {
        id: "39",
        amount: 3,
        creationDate: new Date("2024-05-09T16:32:01Z"),
        product: product7,
        bartender: customer0,
    },]

    const bill1: Bill = {
        id: "41",
        isPayed: false,
        totalPrice: orders1.reduce((acc, p) => acc + (p.product.price * p.amount), 0),
        customer: customer1,
        session: duplicate(session),
        orders: orders1
    };

    const bill2: Bill = {
        id: "42",
        isPayed: false,
        totalPrice: orders2.reduce((acc, p) => acc + (p.product.price * p.amount), 0),
        customer: customer4,
        session: duplicate(session),
        orders: orders2
    };

    const bill3: Bill = {
        id: "43",
        isPayed: false,
        totalPrice: orders3.reduce((acc, p) => acc + (p.product.price * p.amount), 0),
        customer: customer5,
        session: duplicate(session),
        orders: orders3
    };

    session.bills.push(bill1);
    session.bills.push(bill2);
    session.bills.push(bill3);

    const bar: Bar = {
        id: "51",
        name: "Mos Eisley Cantina",
        address: "451 Red Sun Lane, Tatooine",
        email: "contact@moseisleycantina.to",
        phoneNumber: "+31124567890",
        sessions: [session],
        customers: [customer0, customer1, customer2, customer3, customer4, customer5, customer6, customer7]
    }

    return {
        users: [admin, obiwan],
        bars: [bar],
        connectionTokens: []
    };
}
