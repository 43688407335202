export type Store = {
    users: User[],
    bars: Bar[],
    connectionTokens: ConnectionTokens[]
}

export type Statistics = {
    mostSoldProduct: Product,
    mostExpensiveBill: Bill,
    totalSpent: number,
    totalNotYetPayed: number,

}

export type ConnectionTokens = {
    token: string,
    barId: string,
}

export type User = {
    id: string
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    username: string,
    phoneNumber: string | undefined,
    authorizations: BarAuthorization[],
}

export type BarAuthorization = {
    barId: string,
    role: Role
}

export enum Role {
    OWNER,
    BARTENDER,
    CUSTOMER
}


export type Bar = {
    id: string,
    name: string,
    address: string,
    email: string,
    phoneNumber: string,
    sessions: Session[],
    customers: Customer[],
}

export type Session = {
    id: string;
    name: string;
    creationDate: Date;
    date: Date;
    closedDate: Date | undefined;
    isLocked: boolean;
    bills: Bill[];
}


export type Bill = {
    id: string;
    isPayed: boolean;
    totalPrice: number;
    customer: Customer;
    session: Session;
    orders: Order[]
};

export type Order = {
    id: string;
    product: Product;
    amount: number;
    bartender: Customer;
    creationDate: Date;
}

export type Product = {
    id: string;
    name: string;
    brand: string;
    price: number;
}

export type Customer = {
    id: string;
    userId: string;
    name: string;
};