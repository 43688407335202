import React, { useContext } from "react";

import "./tungstun-header.scss";
import TungstunTextButton from './../text-button/tungstun-text-button';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/BarApiService'
import TungstunNotificationContext from "../../stories/notification/tungstun-notification-provider";

function TungstunHeader({ className, setMenuOpen }) {
  const notificationDispatch = useContext(TungstunNotificationContext);
  const navigate = useNavigate();

  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  const handleDonateClick = () => {
    navigate("/donate")
  }

  const handleResetDummyDB = () => {
    ApiService.resetDB();
    notificationDispatch({
      type: "ADD_NOTIFICATION",
      payload: { text: "Demo data has been reset" },
    });
    navigate("/auth/login");
  }

  return (
    <div className={`header__container ${className}`}>
      <div className="header__left">
        <button
          aria-label="Open menu"
          onClick={handleOpenMenu}
          className="menu__button"
        >
          <img
            alt="menu"
            className="menu__image"
            src={require("../../assets/icons/menu.png")}
          />
        </button>
      </div>
      <div className="header__center">bartap</div>
      <div className="header__right">
        {process.env.REACT_APP_DEMO &&
          <TungstunTextButton className={"header__bmcLink"} onClick={handleResetDummyDB} text={"Reset demo data"} width={150} />
        }
        {!process.env.REACT_APP_DEMO &&
          <TungstunTextButton className={"header__bmcLink"} onClick={handleDonateClick} text={"💸"} />
        }
      </div>
    </div>
  );
}

export default TungstunHeader;
